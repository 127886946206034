<template>
  <BModal
    id="modal-publish"
    ref="modal-publish"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="my-2">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
    </div>
    <h3 class="text-black text-center">
      <strong>Konfirmasi</strong>
    </h3>
    <p class="text-center text-black my-2">
      {{ emailSender }} akan mengirimkan Blast Email dengan subjek <span class="font-semibold">{{ title }}</span> kepada total
      <span class="font-semibold">
        {{ totalTargets }}
      </span> orang penerima. Konfirmasi aksi ini?
    </p>

    <BRow class="flex justify-center mb-2">
      <BCol>
        <BButton
          class="rounded-lg"
          variant="outline-primary"
          block
          @click="$bvModal.hide('modal-publish')"
        >
          Batal
        </BButton>
      </BCol>
      <BCol>
        <BButton
          class="rounded-lg"
          :variant="loading ? 'secondary' : 'primary'"
          :disabled="loading"
          block
          @click.prevent="onSubmit()"
        >
          <BSpinner
            v-if="loading"
            variant="light"
            small
            class="mr-1"
          />
          Kirim
        </BButton>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'

export default {
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    emailSender: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    totalTargets: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      emailTesting: '',
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      const url = '/sender/api/v1/email/send-blast-email'
      await newAxiosIns.post(url, this.payload)
        .then(() => {
          this.$toast_success({ message: 'Campaign berhasil dikirim' })
          this.$router.push('/campaign/blast-email')
          this.loading = false
        })
        .catch(() => {
          this.$toast_error({ message: 'Campaign gagal dikirim' })
          this.loading = false
        })
    },
  },
}
</script>
